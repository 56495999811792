import { AirDatepickerLocale } from "air-datepicker";

export const DATEPICKER_LANGUAGE: AirDatepickerLocale = {
  clear: "Clear",
  dateFormat: "dd.mm.yyyy",
  days: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  firstDay: 1,
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthsShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  timeFormat: "hh:ii aa",
  today: "Today",
};
